import { MouseEventHandler, useEffect, useState } from "react";
import { Picture } from "./Picture";

type PlayerProps = {
  album: any;
  photos: any[];
};
export function Player({ photos }: PlayerProps) {
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    function keyPressHandler(event: KeyboardEvent) {
      if (event.code === "ArrowRight") {
        setIndex((index + 1) % photos.length);
      }
      if (event.code === "ArrowLeft") {
        if (index === 0) {
          setIndex(photos.length - 1);
          return;
        }
        setIndex((index - 1) % photos.length);
      }
    }
    // eslint-disable-next-line no-restricted-globals
    addEventListener("keydown", keyPressHandler);

    // eslint-disable-next-line no-restricted-globals
    return () => removeEventListener("keydown", keyPressHandler);
  }, [index, setIndex, photos.length]);

  const clickHandler: MouseEventHandler<HTMLButtonElement> = (event) => {
    // @ts-ignore
    if (event.target?.getAttribute("id") === "picture") {
      setIndex((index + 1) % photos.length);
      // @ts-ignore
    } else if (event.target?.getAttribute("id") === "inc") {
      setIndex((index + 1) % photos.length);
      // @ts-ignore
    } else if (event.target?.getAttribute("id") === "dec") {
      if (index === 0) {
        setIndex(photos.length - 1);
        return;
      }
      setIndex((index - 1) % photos.length);
    }
  };
  const { name } = photos[index];
  const title = parseTitle(name);
  return (
    <div className="flex flex-col max-w-full max-h-full">
      <div className="flex my-2">
        <button className="mr-2" onClick={clickHandler} id="dec">
          {"Previous"}
        </button>
        <span>/</span>
        <button className="ml-2" onClick={clickHandler} id="inc">
          {"Next"}
        </button>
        <span className="ml-5 text-[#777]">{`(${index + 1} of ${
          photos.length
        })`}</span>
      </div>
      {/* @ts-ignore */}
      <div onClick={clickHandler}>
        <Picture alt={""} title={title} src={`./${name}`} />
      </div>
    </div>
  );
}

function parseTitle(name: string): string {
  // handle 1.jpg -> untitled
  if (Number.isInteger(parseInt(name))) {
    return "UNTITLED";
  }
  let title = name.split(".")[0];
  // handle foo1.jpg -> foo
  if (Number.isInteger(parseInt(title[title.length - 1]))) {
    title = title.slice(0, title.length - 1);
  }
  return title.toUpperCase();
}

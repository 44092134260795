import { ReactNode, useState } from "react";
import { Link, useMatch } from "react-router-dom";
import { NavProps } from "./types";

export function DesktopNav({ albums }: NavProps) {
  const items = albums.map(({ name }) => {
    return <NavLink key={name} to={`/${name.toLowerCase()}`}>{name}</NavLink>;
  });
  return (
    <div className="hidden md:block md:mt-5 md:mr-24">
      <h1 className="text-3xl font-bold underline">
        <Link className="whitespace-nowrap" to="/">Runze Yu</Link>
      </h1>
      {items}
      <NavLink to="/about">About</NavLink>
    </div>
  );
}

export function MobileNav({ albums }: NavProps) {
  const [isOpen, setOpen] = useState<boolean>(false);
  const menuClickHandler = () => setOpen((current) => !current);
  const items = albums.map(({ name }) => {
    return <NavLink key={name} to={`/${name.toLowerCase()}`}>{name}</NavLink>;
  });
  return (
    <div className="mb-2.5">
      <div className="md:hidden flex justify-between">
        <h1 className="text-3xl font-bold">
          <Link to="/">Runze Yu</Link>
        </h1>
        <nav className="md:hidden">
          <button onClick={menuClickHandler}>Menu</button>
        </nav>
      </div>
      {isOpen && (
        <>
          {items}
          <NavLink to="/about">About</NavLink>
        </>
      )}
    </div>
  );
}

function NavLink({ to, children }: { to: string; children: ReactNode }) {
  const match = useMatch(to)
  const commonStyle = "md:block md:my-1.5 mr-1.5"
  const style = match ? commonStyle + " text-red-700" : commonStyle
  return (
    <Link className={style} to={to}>
      {children}
    </Link>
  );
}

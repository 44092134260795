import { useRef } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Player } from "./Player";

export function Folder() {
  const data = (window as any).xx;
  const ref = useRef<string | undefined>();
  const { album } = useParams();
  ref.current = album;
  const folder = data.find(({ name }: any) => {
    return name.toLowerCase() === album;
  });
  if (!folder) {
    return <Navigate to="/404" replace={true} />;
  }

  return (
    <div className="flex justify-center w-full">
      <Player key={album} album={album} photos={folder.photos} />
    </div>
  );
}

export function Picture({
  src,
  alt,
  title,
}: {
  src: string;
  alt: string;
  title: string
}) {
  return (
    <div className="text-center">
      <img id="picture" className="max-w-full max-h-[75vh]" src={src} alt={alt} />
      <p className="mt-2">{title}</p>
    </div>
  );
}

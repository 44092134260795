import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { DesktopNav, MobileNav } from "./Nav";

type Album = {
  name: string;
};

function App() {
  const [albums, setAlbums] = useState<Album[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const folders = (window as any).xx;
    setAlbums(
      folders.sort((f1: any, f2: any) => {
        return f1.name > f2.name ? 1 : -1
      })
    );
    if (location.pathname === "/") {
      return navigate("/fashion");
    }
  }, [navigate, location]);
  return (
    <div className="font-mono mx-8 mt-8">
      <MobileNav albums={albums} />
      <div className="block md:flex">
        <DesktopNav albums={albums} />
        <Outlet />
      </div>
    </div>
  );
}

export default App;
